<script setup lang="ts">
import type { Asset } from "contentful";
import { isTypeArticle, isTypePage } from "~/types/contentful";

const page = useLoadedContent();

const contentConfig = useContentConfig();

const metaTitle = computed(() => {
  let title;

  if (page.value) {
    if (isTypeArticle(page.value) || isTypePage(page.value)) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      title = page.value.fields.metaTitle || page.value.fields.title;
    }
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return title || page.value?.fields.slug || "";
});

const metaDescription = computed(() => {
  let description;

  if (page.value) {
    if (isTypePage(page.value)) {
      description = page.value.fields.metaDescription ?? page.value.fields.excerpt;
    } else if (isTypeArticle(page.value)) {
      description = page.value.fields.metaDescription ?? page.value.fields.subtitle;
    }
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return description || contentConfig.value?.metaDescriptionFallback || "";
});

const metaImage = computed(() => {
  let url: Asset | undefined;

  if (page.value) {
    url = page.value.fields.image;
  }

  if (!url) {
    url = contentConfig.value?.metaImageFallback;
  }

  return url
    ? `https:${String(url.fields.file?.url).replace(
      "downloads.",
      "images.",
    )}?f=top&fit=fill&w=1200&h=630`
    : "";
});

const isArticle = computed(() => page.value && isTypeArticle(page.value));

const metaTitleBase = computed(() => contentConfig.value?.metaTitleBase ?? "");

useHead({
  title: metaTitle,
  ...(metaTitleBase.value ? {} : { titleTemplate: null }),
});

useServerSeoMeta({
  description: metaDescription,
  ogDescription: metaDescription,
  ogImage: metaImage,
  ogTitle: () => `${metaTitle.value}${metaTitleBase.value ? ` | ${metaTitleBase.value}` : ""}`,
  ogType: isArticle.value ? "article" : "website",
  ogUrl: useRuntimeConfig().baseUrl + useRoute().fullPath,
  twitterCard: "summary_large_image",
  twitterDescription: metaDescription,
  twitterImage: metaImage,
  twitterTitle: () => `${metaTitle.value}${metaTitleBase.value ? ` | ${metaTitleBase.value}` : ""}`,
});

const overlay = useOverlay();
</script>

<template>
  <div class="flex min-h-svh flex-col overflow-x-hidden">
    <!-- overlay -->
    <Transition name="fade">
      <div
        v-if="overlay"
        class="left-0 z-20 size-full h-full bg-black/60 max-lg:hidden lg:fixed"
      />
    </Transition>

    <AppHeader />

    <NuxtLoadingIndicator />

    <main class="grow bg-white">
      <slot />
    </main>

    <AppFooter />
  </div>
</template>
